export const CIAM_SDK_NAMESPACE = 'ciamSdk';
export const COUNTDOWN = 60;
export const FORMAT = 'YYMMDDHHmmss';
export const EXPIRING_MODAL = 'expiringModal';
export const EXPIRING_MODAL_CONTENT = 'epiring-content';
export const LAST_ACTIVE = 'rdcpLastActive';
export const TIMEOUT = 1800000;
export const USER_ACTIVITY = 'user-activity';
export const USER_ACTIVITY_PROVIDER = 'provider';
export const TABS = 'rdcpTabs';
export const BEFORE_UNLOAD = 'beforeunload';
export const IS_WHATS_NEW_VIEWED = 'isWhatsNewViewed';
export const URL_TYPE_PARAM = 'type';
export const URL_TYPE_EXTERNAL = 'external';
export const URL_TYPE_CODE = 'code';
