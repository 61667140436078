import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { selectGigyaToken } from '../../../app/session/core/config/config.selectors';
import { selectAccessToken } from '../../../app/session/core/oidc/oidc.selectors';
import { SaveErrorModal } from '../../../domains/patient/scenes/INS-request-modal/save-error-modal/save-error.modal';
import { addAlertAction } from '../../../shared/design-system/alerts/store/alert.actions';
import { ALERT_POSITION } from '../../../shared/design-system/alerts/store/alert.constans';
import { AlertType } from '../../../shared/design-system/alerts/store/alert.types';
import { createModal } from '../../../shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from '../../../shared/design-system/modal/store/modal/modal.constants';
import { TargetRangesErrorModal } from '../components/range-error-modal/range-error.modal';
import {
  getTargetRanges,
  saveTargetRanges,
  TARGET_RANGES_GET_REQUEST,
  TARGET_RANGES_SAVE_REQUEST,
} from './target-ranges.actions';

export const getTargetRangesEpic =
  (getTargetRangesService) => (action$, state$) =>
    action$.ofType(TARGET_RANGES_GET_REQUEST.START).flatMap(({ payload }) => {
      const openId = {
        accessToken: selectAccessToken(state$.getState()),
        apiKey: selectGigyaToken(state$.getState()),
      };
      return Observable.fromPromise(getTargetRangesService(openId, payload))
        .switchMap((targetRanges) => [getTargetRanges.success(targetRanges)])
        .pipe(
          catchError((err) =>
            Observable.concat(
              Observable.of(getTargetRanges.error(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: TargetRangesErrorModal,
                  },
                }),
              ),
            ),
          ),
        );
    });

export const saveTargetRangesEpic =
  (saveTargetRangesService) => (action$, state$) =>
    action$.ofType(TARGET_RANGES_SAVE_REQUEST.START).flatMap(({ payload }) => {
      const openId = {
        accessToken: selectAccessToken(state$.getState()),
        gigyaToken: selectGigyaToken(state$.getState()),
      };
      return Observable.fromPromise(
        saveTargetRangesService(openId, payload.range, payload.patientId),
      )
        .switchMap((targetRanges) => [
          saveTargetRanges.success(targetRanges),
          getTargetRanges.start(payload.patientId),
          addAlertAction({
            type: AlertType.SUCCESS,
            text: { [AlertType.SUCCESS]: 'alert.settings.success.message' },
            position: ALERT_POSITION.BOTTOM_RIGHT,
          }),
        ])
        .pipe(
          catchError((err) =>
            Observable.concat(
              Observable.of(saveTargetRanges.error(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: SaveErrorModal,
                    title: 'errorSettingsModal.header',
                    content: 'errorSettingsModal.targetRange.content',
                    textPrimaryBtn: 'errorSettingsModal.okBtn',
                  },
                }),
              ),
            ),
          ),
        );
    });
