import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { selectAccessToken } from 'src/app/session/core/oidc/oidc.selectors';
import { catchError } from 'rxjs/operators';
import {
  UnblindBodyData,
  UnblindPatientLoaderImpl,
} from '../../../../services/patient/clinical-studies/interventional-clinical-studies/unblind-clinical-study-patient/unblind-clinical-study-patient.types';
import {
  UnblindPatientActionType,
  UnblindPatientStartAction,
} from './unblind-clinical-study-patient.types';
import {
  unblindClinicalStudyPatientError,
  unblindClinicalStudyPatientSuccess,
} from './unblind-clinical-study-patient.actions';
import { createModal } from 'src/shared/design-system/modal/store/modal/modal.actions';
import { MODAL_TYPES } from 'src/shared/design-system/modal/store/modal/modal.constants';
import { UnblindStudyErrorModalComponent } from 'src/domains/patient/scenes/clinical-studies/interventional-clinical-studies/modals/unblind-study-error-modal/unblind-study-error-modal.component';
import { addAlertAction } from 'src/shared/design-system/alerts/store/alert.actions';
import { AlertType } from 'src/shared/design-system/alerts/store/alert.types';
import { ALERT_POSITION } from 'src/shared/design-system/alerts/store/alert.constans';
import { State } from 'src/app/store/app.types';

export const unblindClinicalStudyPatientEpic: (
  unblindClinicalStudyPatientService: UnblindPatientLoaderImpl,
) => Epic<FixMe, State> =
  (unblindClinicalStudyPatientService) => (action$, store$) =>
    action$
      .ofType(UnblindPatientActionType.UNBLIND_PATIENT_START)
      .flatMap(({ payload }: UnblindPatientStartAction) => {
        const accessToken = selectAccessToken(store$.getState());
        const patientId = payload;
        return Observable.fromPromise(
          unblindClinicalStudyPatientService(accessToken, patientId),
        )
          .flatMap((data: UnblindBodyData) => {
            return [
              unblindClinicalStudyPatientSuccess(data),
              addAlertAction({
                type: AlertType.SUCCESS,
                text: {
                  [AlertType.SUCCESS]:
                    'interventionalClinicalStudy.successAlertText',
                },
                position: ALERT_POSITION.TOP_RIGHT,
              }),
            ];
          })
          .pipe(
            catchError((err) =>
              Observable.concat(
                Observable.of(unblindClinicalStudyPatientError(err)),
                Observable.of(
                  createModal({
                    key: MODAL_TYPES.CUSTOM,
                    data: {
                      customComponent: UnblindStudyErrorModalComponent,
                    },
                  }),
                ),
              ),
            ),
          );
      });
