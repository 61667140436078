import { endpointWithParams } from '@roche/roche-common';
import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from '../../../../../../../shared/utils/service/service.utils';
import { ENDPOINTS } from '../../../../../../../app/navigation/services/service.constants';
import {
  EnrollPatientToStudyLoaderImplType,
  EnrollPatientToStudyResponseType,
  EnrollPatientToStudyServiceImplType,
  EnrollPatientToStudyTransformImpType,
} from './enroll-patient-to-study.types';

export const EnrollPatientToStudyLoaderImpl: EnrollPatientToStudyLoaderImplType =
  (accessToken, fhirId, requestBody) =>
    postJSON(
      endpointWithParams(ENDPOINTS.enrollPatientToObservationalStudy, {
        fhirId,
      }),
      {
        headers: {
          Authorization: createAuthHeader(accessToken),
        },
        body: stringifyBody({
          studyId: requestBody.studyId,
          siteId: requestBody.siteId,
          participantId: requestBody.participantId,
          status: requestBody.status,
        }),
      },
    );

export const EnrollPatientToStudyTransformImp: EnrollPatientToStudyTransformImpType =
  (response: EnrollPatientToStudyResponseType) => response;

export const EnrollPatientToStudyServiceImpl: EnrollPatientToStudyServiceImplType =
  (load, transform) => (accessToken, fhirId, studyData) =>
    load(accessToken, fhirId, studyData).then(transform);
