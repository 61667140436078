import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetParticipantIdsServiceImplType } from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-participant-ids/get-participant-ids.types';
import {
  GetParticipantIdsActionType,
  GetParticipantIdsActions,
  GetParticipantIdsStartAction,
} from './get-participant-ids.types';
import { State } from '../../../../../../../app/store/app.types';
import { selectAccessToken } from '../../../../../../../app/session/core/oidc/oidc.selectors';
import {
  getParticipantIdsError,
  getParticipantIdsSuccess,
} from './get-participant-ids.actions';

export const getParticipantIdsEpic: (
  getParticipantIdsService: ReturnType<GetParticipantIdsServiceImplType>,
) => Epic<GetParticipantIdsActions, State> =
  (getParticipantIdsService) => (action$, store$) =>
    action$
      .ofType(GetParticipantIdsActionType.GET_PARTICIPANT_IDS_START)
      .flatMap(({ payload }: GetParticipantIdsStartAction) => {
        const accessToken = selectAccessToken(store$.getState());

        return Observable.fromPromise(
          getParticipantIdsService(accessToken, payload),
        )
          .switchMap((response) => {
            return [getParticipantIdsSuccess(response)];
          })
          .pipe(
            catchError((response) =>
              Observable.of(getParticipantIdsError(response)),
            ),
          );
      });
