import { connect } from 'react-redux';
import { SidebarmenuJelloComponent } from './sidebar-menu-jello.component';
import { mapDispatchers } from 'src/shared/utils/map-dispatchers';
import { createStructuredSelector } from 'reselect';
import { push } from 'react-router-redux';
import { clearPatientDateRange } from 'src/domains/patient-dashboard/store/patient-date-range/patient-date-range.action';
import { clearCgmDateRange } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';
import {
  PATIENT_PERMISSIONS as PP,
  PERMISSIONS as P,
} from 'src/domains/permissions/store/permissions.constants';
import {
  ADMINISTRATIVE,
  GENERAL_PRACTITIONER,
} from 'src/app/store/user/user.constants';
import { updateDashboardLocationIsolated } from 'src/domains/general/widgets/store/hcp-dashboard.actions';

const hasPermissions = (permissions: string[]) => (permission: string) =>
  Array.isArray(permissions) && permissions.includes(permission);

const mapStateToProps = (_, props) => {
  const { activeDevices, permissions, patientPermissions, profile } = props;

  const hasPatientPermissions = hasPermissions(patientPermissions);

  const hasBlindedStudyPermissions = hasPatientPermissions(PP.BLINDED_STUDY);

  const isStripMngProfessional = permissions.includes(P.STRIP_MNG_PROFESSIONAL);
  const isDeliveryProfessional = permissions.includes(P.DELIVERY_PROFESSIONAL);
  const isStripMngPatient = hasPatientPermissions(PP.STRIP_MNG_PATIENT);
  const isHomeDeliveryPatient = hasPatientPermissions(PP.HOME_DELIVERY_PATIENT);

  const hasProfessionalStripAndDeliveryPermissions = () =>
    isStripMngProfessional || isDeliveryProfessional;
  const hasPatientOrProfessionalPermissions = () =>
    (isStripMngPatient || isHomeDeliveryPatient) &&
    hasProfessionalStripAndDeliveryPermissions();
  const isStripMngDisabled = () =>
    !hasPatientOrProfessionalPermissions() || hasBlindedStudyPermissions;

  const isCgmDisabled = () => hasBlindedStudyPermissions;
  const isPatternDisabled = () => hasBlindedStudyPermissions;

  return {
    disabled: createStructuredSelector({
      bgm: () => false,
      cgm: isCgmDisabled,
      patterns: isPatternDisabled,
      stripManagement: isStripMngDisabled,
      patientDevices: () => activeDevices.length === 0,
      editPatient: () => false,
    }),
    hidden: createStructuredSelector({
      bgm: () => profile === ADMINISTRATIVE,
      cgm: () => profile === ADMINISTRATIVE,
      patterns: () => !permissions.includes(P.ADVANCED_INDICATORS),
      stripManagement: () =>
        !(
          permissions.includes(P.STRIP_MNG_PROFESSIONAL) ||
          permissions.includes(P.DELIVERY_PROFESSIONAL)
        ) ||
        (profile === ADMINISTRATIVE &&
          hasPatientPermissions(PP.HOME_DELIVERY_PATIENT)),
      patientDevices: () =>
        profile === ADMINISTRATIVE || profile === GENERAL_PRACTITIONER,
      editPatient: () => false,
    }),
    isPatientStrip: hasPatientPermissions(PP.STRIP_MNG_PATIENT),
    isPatientHomeDelivery: hasPatientPermissions(PP.HOME_DELIVERY_PATIENT),
  };
};

const mapDispatchToProps = mapDispatchers({
  goTo: (path) => push(path),
  onClearBgDateRange: clearPatientDateRange,
  onClearCgmDateRange: clearCgmDateRange,
  updateDashboardLocation: updateDashboardLocationIsolated,
});

export const SidebarMenuComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarmenuJelloComponent);
