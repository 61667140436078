import { matchPath } from 'react-router-dom';
import { PARSED_LOCATION } from './constants';
import { routes } from 'src/domains/routes';

export const matchPatientDashboardLocation = (location: Location) => {
  const patientDashboardRoutes = [
    {
      path: routes.patient.patientBGDashboard,
      location: PARSED_LOCATION.bgm,
    },
    {
      path: routes.patient.patientCGMDashboard,
      location: PARSED_LOCATION.cgm,
    },
  ];

  return patientDashboardRoutes.find(({ path }) =>
    matchPath(location?.pathname, { path, exact: true, strict: false }),
  );
};
