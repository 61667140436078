import React, { useCallback, useEffect, useState } from 'react';
import { SidebarMenuComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/sidebar-menu-jello/sidebar-menu-jello.container';
import { PatientDataBarComponent } from 'src/domains/patient-data-bar/patient-data-bar-jello/patient-data-bar-jello.component';
import { VisitModuleActiveSection } from './visit-module-active-section';

import styles from './styles.module.css';
import { isEmpty } from 'ramda';
import { RenderIf } from 'src/shared/utils/render-if';

export const JelloWrapperComponent = (props) => {
  const {
    activeDevices,
    cgmSummary,
    Content,
    fhirId,
    isPlatformUser,
    patient,
    patientPermissions,
    patientProfileType,
    permissions,
    profile,
    location,
  } = props;

  const [visitModuleActiveSection, setVisitModuleActiveSection] =
    useState<VisitModuleActiveSection>(VisitModuleActiveSection.CLOSE);

  const handleEvent = useCallback(() => {
    setVisitModuleActiveSection(VisitModuleActiveSection.CLOSE);
  }, [setVisitModuleActiveSection]);

  useEffect(() => {
    if (isEmpty(patient)) {
      props.fetchPatient({ patientId: props.match?.params?.id });
    }

    if (fhirId) {
      props.getCGMSummary({ fhirId });
    }

    window.addEventListener('vc-module-close-event', handleEvent);

    return () => {
      window.removeEventListener('vc-module-close-event', handleEvent);
    };
  }, [handleEvent]);

  const contentProps = { ...props, visitModuleActiveSection };

  const patientDataBarComponentProps = {
    patient,
    profile,
    isPlatformUser,
    visitModuleActiveSection,
    setVisitModuleActiveSection,
    location,
  };

  const sidebarMenuComponentProps = {
    patientId: patient?.id,
    location: window.location,
    permissions,
    patientPermissions,
    cgmSummary,
    activeDevices,
    patientProfileType,
    profile,
  };

  return (
    <>
      <PatientDataBarComponent {...patientDataBarComponentProps} />
      <div className={styles.wrapper}>
        <SidebarMenuComponent {...sidebarMenuComponentProps} />
        <RenderIf validate={props.patientPermissions.length}>
          <Content {...contentProps} />
        </RenderIf>
      </div>
    </>
  );
};
