import { t } from 'i18next';
import React from 'react';
import {
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
  JELLO_TOOLTIP_POSITIONS,
} from '../../../../app/app.jello.constants';
import { JelloTooltip } from '../../../../shared/design-system/jello-tooltip/jello-tooltip.component';
import { RenderIf } from '../../../../shared/utils/render-if';
import {
  formatDiagnosticsDate,
  formatDoB,
  getShortTranslatedDiabetesType,
  getShortTranslatedTherapyType,
  getTranslatedDiabetesType,
  getTranslatedTherapyType,
} from '../patient-data-bar-jello.utils';
import {
  PATIENT_HCID_TOOLTIP,
  PATIENT_NAV_DIABETES_TYPE_TOOLTIP,
  PATIENT_NAV_THERAPY_TYPE_TOOLTIP,
} from '../patient-data-bar.constants';
import styles from './styles.module.css';
import { getJelloAttributeValueByCondition } from '../../../general/widgets/dashboard-jello/utils';

const EMPTY_ID_TEXT = '-';

const { S } = JELLO_TEXT_SIZES;
const { SEMIBOLD } = JELLO_TEXT_WEIGHTS;

const SectionTitle = ({ title }) => {
  const jelloTextProps = {
    class: styles.patientDataTitle,
    size: S,
    weight: SEMIBOLD,
  };

  return <jello-text {...jelloTextProps}>{title}</jello-text>;
};

const SectionText = ({ text }) => {
  const jelloTextProps = {
    class: styles.patientMainDataText,
    size: S,
    weight: SEMIBOLD,
    ellipsis: true,
  };

  return <jello-text {...jelloTextProps}>{text}</jello-text>;
};

const SimpleSection = ({ title, text }) => {
  const sectionTittleProps = { title };
  const sectionTextProps = { text };

  return (
    <div className={styles.patientTitleAndInfoWrapper}>
      <SectionTitle {...sectionTittleProps} />
      <SectionText {...sectionTextProps} />
    </div>
  );
};

const TooltipSection = ({
  isTooltipDisabled,
  text,
  title,
  tooltipId,
  tooltipLabel,
}) => {
  const sectionTitleProps = { title };
  const sectionTextProps = { text };
  const jelloTooltipProps = {
    id: tooltipId,
    label: tooltipLabel,
    disabled: isTooltipDisabled,
    position: JELLO_TOOLTIP_POSITIONS.BOTTOM,
  };

  return (
    <div className={`${styles.patientTitleAndInfoWrapper} ${styles.idWrapper}`}>
      <SectionTitle {...sectionTitleProps} />
      <JelloTooltip {...jelloTooltipProps}>
        <SectionText {...sectionTextProps} />
      </JelloTooltip>
    </div>
  );
};

export const PatientSecondaryData = ({
  dateOfBirth,
  diabetesTypeEC6,
  diagnosticDate,
  healthCareSystemId,
  isPlatformUser,
  therapyType,
}) => {
  const hasHealthCareSystemId = !!healthCareSystemId;
  const patientIdProps = {
    tooltipId: PATIENT_HCID_TOOLTIP,
    tooltipLabel: healthCareSystemId,
    isTooltipDisabled: getJelloAttributeValueByCondition(
      !hasHealthCareSystemId,
    ),
    title: t('patientNav.id'),
    text: hasHealthCareSystemId ? healthCareSystemId : EMPTY_ID_TEXT,
    className: styles.idWrapper,
  };

  const patientBirthProps = {
    title: t('patientNav.dateOfBirth'),
    text: formatDoB(dateOfBirth),
  };

  const patientDiabetesTypeProps = {
    tooltipId: PATIENT_NAV_DIABETES_TYPE_TOOLTIP,
    tooltipLabel: t(getTranslatedDiabetesType(diabetesTypeEC6)),
    isTooltipDisabled: getJelloAttributeValueByCondition(!diabetesTypeEC6),
    title: t('patientNav.diabetes'),
    text: t(getShortTranslatedDiabetesType(diabetesTypeEC6)),
  };

  const patientTherapyProps = {
    tooltipId: PATIENT_NAV_THERAPY_TYPE_TOOLTIP,
    tooltipLabel: t(getTranslatedTherapyType(therapyType)),
    isTooltipDisabled: getJelloAttributeValueByCondition(!therapyType),
    title: t('patientNav.therapyTitle'),
    text: t(getShortTranslatedTherapyType(therapyType)),
  };

  const patientDiagnosedProps = {
    title: t('patientNav.diagnosed'),
    text: formatDiagnosticsDate(diagnosticDate),
  };

  return (
    <div className={styles.patientSecondaryDataWrapper}>
      <TooltipSection {...patientIdProps} />
      <SimpleSection {...patientBirthProps} />
      <TooltipSection {...patientDiabetesTypeProps} />
      <RenderIf validate={isPlatformUser}>
        <TooltipSection {...patientTherapyProps} />
      </RenderIf>
      <SimpleSection {...patientDiagnosedProps} />
    </div>
  );
};
