// Description: Utility functions for the dashboard jello widget
import { times } from 'ramda';
import { FiltersType } from 'src/domains/general/widgets/components/advanced-search/filters/filters.constants';

const NUMBER_LOW_RESOLUTION = 1336;
const isLowResolution = () => window.outerWidth <= NUMBER_LOW_RESOLUTION;

// Returns the number of pages based on the number of results and the number of results per page
const getNumberOfPages = (results: number, resultsPerPage: number) => {
  const totalPages = Math.ceil(results / resultsPerPage) || 0;
  return times((x: number) => 1 + x, totalPages);
};

const areFiltersApplied = (filters: FiltersType): boolean => {
  const filtersApplied = Object.values(filters).find(
    (item) => item?.[0] !== 'all',
  );
  return filtersApplied !== undefined;
};

const getJelloAttributeValueByCondition = (condition: boolean) => {
  return condition ? true : null;
};

export {
  getNumberOfPages,
  isLowResolution,
  areFiltersApplied,
  NUMBER_LOW_RESOLUTION,
  getJelloAttributeValueByCondition,
};
