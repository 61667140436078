import { endpointWithParams } from '@roche/roche-common';

import {
  createAuthHeader,
  getJSON,
} from '../../../../../../../shared/utils/service/service.utils';
import { ENDPOINTS } from '../../../../../../../app/navigation/services/service.constants';
import {
  GetParticipantIdsLoaderImplType,
  GetParticipantIdsResponseType,
  GetParticipantIdsServiceImplType,
  GetParticipantIdsTransformImpType,
} from './get-participant-ids.types';
import { transformParticipantIdsResponse } from '../../utils';

export const GetParticipantIdsLoaderImpl: GetParticipantIdsLoaderImplType = (
  accessToken: string,
  studyId,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.getParticipantIds, {
      studyId,
    }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const GetParticipantIdsTransformImp: GetParticipantIdsTransformImpType =
  (response: GetParticipantIdsResponseType) =>
    transformParticipantIdsResponse(response);

export const GetParticipantIdsServiceImpl: GetParticipantIdsServiceImplType =
  (load, transform) => (accessToken: string, studyId: string) =>
    load(accessToken, studyId).then(transform);
