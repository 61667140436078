import React from 'react';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { PatientType } from '../../../../domains/patient/services/patient/patient.types';
import { selectPatient } from '../../../../domains/patient/store/patient/patient.selector';
import { withLoader } from '../../../../shared/design-system/with-loader/with-loader.component';
import { getRouterPathname } from '../../store/navigation.selectors';

const selectIsPatientInCurrentPath = createSelector(
  getRouterPathname,
  selectPatient,
  (currentRouterPath: string, patient: PatientType) => {
    return currentRouterPath.includes(patient.id?.toString());
  },
);

type IdValidationProps = {
  isPatientInPath: boolean;
};

const loaderProps = {
  text: '',
  infinite: true,
  flexibleHeight: true,
  minHeight: '200',
};

const validators = {
  isPatientInPath: (val: boolean) => val === true,
};

const withLoaderProps = {
  loaderProps,
  validators,
};

export const withPatientIdValidation = <P extends object>(
  Component: React.ComponentType<P>,
) =>
  connect(
    createStructuredSelector({
      isPatientInPath: selectIsPatientInCurrentPath,
    }),
  )(
    withLoader(withLoaderProps)(
      ({ isPatientInPath, ...props }: IdValidationProps) => {
        return <Component {...(props as P)} />;
      },
    ),
  );
