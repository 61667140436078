import {
  DiabetesType,
  TherapyType,
} from 'src/domains/patient/services/patient/patient.types';

export enum DevicesTypes {
  Normal = 'ICO_4-51_normal',
  Meter = 'ICO_3-18_meter',
  Meterpump = 'ICO_3-21_meterpump',
  Cgm = 'ICO_3-21_cgm',
  NoDevice = '',
}

export const PATIENT_NAV_THERAPY_TYPE_TOOLTIP =
  'patient-nav-therapy-type-tooltip';
export const PATIENT_NAV_DIABETES_TYPE_TOOLTIP =
  'diabetes-type-tooltip-content';
export const PATIENT_HCID_TOOLTIP = 'patient-hcid-tooltip-content';
export const DEFAULT_NULL_VALUE_STRING = '-';

const PREFIX_TRANSLATION_DIABETES = 'hcpDashboard.';
const PREFIX_TRANSLATION_DIABETES_SHORT_NAME = `${PREFIX_TRANSLATION_DIABETES}shortName.`;

const PREFIX_TRANSLATION_THERAPY = 'patientNav.';
const PREFIX_TRANSLATION_THERAPY_NAME = `${PREFIX_TRANSLATION_THERAPY}therapyTypeName.`;
const PREFIX_TRANSLATION_THERAPY_SORT_NAME = `${PREFIX_TRANSLATION_THERAPY}shortTherapyTypeName.`;

export const THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION = {
  [TherapyType.MDI]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.MDI}`,
  [TherapyType.CSII]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.CSII}`,
  [TherapyType.BASAL]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.BASAL}`,
  [TherapyType.OAD]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.OAD}`,
  [TherapyType.NII]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.NII}`,
  [TherapyType.BOT]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.BOT}`,
  [TherapyType.FRC]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.FRC}`,
  [TherapyType.AID]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.AID}`,
  [TherapyType.LIFESTYLE]: `${PREFIX_TRANSLATION_THERAPY_NAME}${TherapyType.LIFESTYLE}`,
};

export const THERAPY_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT = {
  [TherapyType.MDI]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.MDI}`,
  [TherapyType.CSII]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.CSII}`,
  [TherapyType.BASAL]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.BASAL}`,
  [TherapyType.OAD]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.OAD}`,
  [TherapyType.NII]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.NII}`,
  [TherapyType.BOT]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.BOT}`,
  [TherapyType.FRC]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.FRC}`,
  [TherapyType.AID]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.AID}`,
  [TherapyType.LIFESTYLE]: `${PREFIX_TRANSLATION_THERAPY_SORT_NAME}${TherapyType.LIFESTYLE}`,
};

export const DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION = {
  [DiabetesType.DIABETES_TYPE1]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.DIABETES_TYPE1}`,
  [DiabetesType.DIABETES_TYPE2]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.DIABETES_TYPE2}`,
  [DiabetesType.DIABETES_GESTATIONAL]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.DIABETES_GESTATIONAL}`,
  [DiabetesType.DIABETES_LADA]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.DIABETES_TYPE_LADA}`,
  [DiabetesType.DIABETES_MODY]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.DIABETES_TYPE_MODY}`,
  [DiabetesType.DIABETES_PREDIABETES]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.DIABETES_PREDIABETES}`,
  [DiabetesType.DIABETES_UNKNOWN]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.UNKNOWN}`,
  [DiabetesType.PENDING_TO_IDENTIFY]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.PENDING_TO_IDENTIFY}`,
  [DiabetesType.DIABETES_TYPE2_MDI]: `${PREFIX_TRANSLATION_DIABETES}${DiabetesType.DIABETES_TYPE2_MDI}`,
};

export const DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION_SHORT = {
  ...DIABETES_TYPE_FROM_SERVER_TO_TRANSLATION,
  [DiabetesType.DIABETES_LADA]: `${PREFIX_TRANSLATION_DIABETES_SHORT_NAME}${DiabetesType.DIABETES_TYPE_LADA}`,
  [DiabetesType.DIABETES_MODY]: `${PREFIX_TRANSLATION_DIABETES_SHORT_NAME}${DiabetesType.DIABETES_TYPE_MODY}`,
};
