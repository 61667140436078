import { endpointWithParams } from '@roche/roche-common';
import {
  createAuthHeader,
  getJSON,
} from '../../../../../../../shared/utils/service/service.utils';
import { ENDPOINTS } from '../../../../../../../app/navigation/services/service.constants';
import {
  GetObservationalStatusLoaderImplType,
  GetObservationalStatusResponseType,
  GetObservationalStatusServiceImplType,
  GetObservationalStatusTransformImpType,
} from './get-observational-studies-status.types';

export const GetObservationalStudiesStatusLoaderImpl: GetObservationalStatusLoaderImplType =
  (accessToken: string, fhirId) => {
    return getJSON(
      endpointWithParams(ENDPOINTS.getObservationalStudiesStatus, { fhirId }),
      {
        headers: {
          Authorization: createAuthHeader(accessToken),
        },
      },
    );
  };

export const GetObservationalStudiesStatusTransformImp: GetObservationalStatusTransformImpType =
  (response: GetObservationalStatusResponseType) => response;

export const GetObservationalStudiesStatusServiceImpl: GetObservationalStatusServiceImplType =
  (load, transform) => (accessToken: string, fhirId: string) =>
    load(accessToken, fhirId).then(transform);
