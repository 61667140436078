import { DEFAULT_FILTERS } from 'src/domains/general/widgets/components/advanced-search/filters/filters.utils';

import {
  CLEAR_CRITERIA,
  FETCH_PATIENTS,
  FILTER_PATIENTS,
  FILTER_PATIENTS_NC,
  FILTER_PATIENTS_SET,
  FILTER_PATIENTS_SET_NC,
  SET_PAGE,
  SET_PER_PAGE,
  SORT_PATIENTS,
  UPDATE_DASHBOARD_LOCATION_ISOLATED,
  UPDATE_SEARCH_BY_TAB,
  UPDATE_SEARCH_TERM,
} from './hcp-dashboard.actions';
import { filterPatients } from './hcp-dashboard.filters';
import { sortPatientsOnHcpDashboard } from './hcp-dashboard.sort';

import {
  ALL_PATIENTS_LIST_SORT,
  FILTER_PATIENTS_LIST_SORT,
} from '../constants';

type HcpDashboardState = {
  allPatients: any[];
  error: boolean;
  searchTerm: string;
  filterPatients?: any[];
  filters: object;
  filtersNc: object;
  sort: any;
  perPage: number;
  page: number;
  searching: boolean;
  bgPatientsNumber: number;
  cgmPatientsNumber: number;
  dashboarLocation: string;
  hasSearch: boolean;
  updatingDashboardLocationIsolated: boolean;
  searchByTab: boolean;
};

export const INITIAL: HcpDashboardState = {
  allPatients: [],
  error: false,
  searchTerm: '',
  filterPatients: [],
  filters: DEFAULT_FILTERS,
  filtersNc: {},
  sort: '',
  perPage: 10,
  page: 1,
  searching: false,
  bgPatientsNumber: 0,
  cgmPatientsNumber: 0,
  dashboarLocation: 'BG',
  hasSearch: false,
  updatingDashboardLocationIsolated: false,
  searchByTab: false,
};

const handleUpdateDashboardLocationIsolated = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  dashboarLocation: action.payload,
  updatingDashboardLocationIsolated: true,
});

const handleUpdateSearchTerm = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  searchTerm: action.payload,
});

const handleFetchPatientsStart = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  searching: true,
  dashboarLocation: action.payload.location,
});

const handleFetchPatientsSuccess = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => {
  const filtersSetted = state.filters !== DEFAULT_FILTERS;
  const nFilters = state.filters;
  return {
    ...state,
    allPatients: action.payload.stats,
    filterPatients: filtersSetted
      ? filterPatients(nFilters, { allPatients: action.payload.stats })
      : action.payload.stats,
    error: false,
    searching: false,
    updatingDashboardLocationIsolated: false,
    bgPatientsNumber: action.payload.bgPatientsNumber,
    cgmPatientsNumber: action.payload.cgmPatientsNumber,
    hasSearch: state.searchTerm.length ? true : false,
    page: INITIAL.page,
    perPage: INITIAL.perPage,
  };
};

const handleFetchPatientsError = (state: HcpDashboardState) => ({
  ...state,
  error: true,
  page: 1,
});

const handleFilterPatients = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  filterPatients: filterPatients(action.filters, state),
  filters: action.filters,
  page: INITIAL.page,
  perPage: INITIAL.perPage,
});

const handleFilterPatientsNc = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => {
  const newFilters = {
    ...DEFAULT_FILTERS,
    ...action.filters,
  };
  return {
    ...state,
    filterPatients: filterPatients(newFilters, state),
    filters: newFilters,
    page: INITIAL.page,
    perPage: INITIAL.perPage,
  };
};

const handleFilterPatientsSet = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => {
  const stateModified = {
    ...action.filters,
    ...state.filtersNc,
  };
  return {
    ...state,
    filters: stateModified,
    filtersNc: {},
  };
};

const handleFilterPatientsSetNc = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  filtersNc: action.filtersNc,
});

const handleSortPatients = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  sort: action.payload,
  allPatients: sortPatientsOnHcpDashboard(
    action.payload,
    state,
    ALL_PATIENTS_LIST_SORT,
  ),
  filterPatients: sortPatientsOnHcpDashboard(
    action.payload,
    state,
    FILTER_PATIENTS_LIST_SORT,
  ),
});

const handleSetPage = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  page: action.payload,
});

const handleSetPerPage = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  perPage: action.payload,
  page: 1,
});

const handleClearCriteria = () => ({
  ...INITIAL,
});

const handleUpdateSearchByTab = (
  state: HcpDashboardState,
  action: ActionDashboardReducer,
) => ({
  ...state,
  searchByTab: action.payload,
});

const actionHandlers = {
  [UPDATE_DASHBOARD_LOCATION_ISOLATED]: handleUpdateDashboardLocationIsolated,
  [UPDATE_SEARCH_TERM]: handleUpdateSearchTerm,
  [FETCH_PATIENTS.START]: handleFetchPatientsStart,
  [FETCH_PATIENTS.SUCCESS]: handleFetchPatientsSuccess,
  [FETCH_PATIENTS.ERROR]: handleFetchPatientsError,
  [FILTER_PATIENTS]: handleFilterPatients,
  [FILTER_PATIENTS_NC]: handleFilterPatientsNc,
  [FILTER_PATIENTS_SET]: handleFilterPatientsSet,
  [FILTER_PATIENTS_SET_NC]: handleFilterPatientsSetNc,
  [SORT_PATIENTS]: handleSortPatients,
  [SET_PAGE]: handleSetPage,
  [SET_PER_PAGE]: handleSetPerPage,
  [CLEAR_CRITERIA]: handleClearCriteria,
  [UPDATE_SEARCH_BY_TAB]: handleUpdateSearchByTab,
};

type ActionTypes = keyof typeof actionHandlers;
type ActionDashboardReducer = {
  type: ActionTypes;
  payload: {
    location: string;
    stats: unknown;
    bgPatientsNumber: number;
    cgmPatientsNumber: number;
    updatingDashboardLocationIsolated: boolean;
  };
  filters: object;
  filtersNc: object;
  location: string;
};

export const hcpDashboardReducer = (
  state = INITIAL,
  action: ActionDashboardReducer,
) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};
