import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import {
  GetClinicalStudiesActionType,
  GetClinicalStudiesActions,
} from './get-clinical-studies.types';
import { selectAccessToken } from '../../../../../../../app/session/core/oidc/oidc.selectors';
import {
  getClinicalStudiesError,
  getClinicalStudiesSuccess,
} from './get-clinical-studies.actions';
import { catchError } from 'rxjs/operators';
import { GetClinicalStudiesServiceImplType } from '../../../../../services/patient/clinical-studies/observational-clinical-studies/get-clinical-studies/get-clinical-studies.types';
import { State } from '../../../../../../../app/store/app.types';

export const getClinicalStudiesEpic: (
  getClinicalStudiesService: ReturnType<GetClinicalStudiesServiceImplType>,
) => Epic<GetClinicalStudiesActions, State> =
  (getClinicalStudiesService) => (action$, store$) =>
    action$
      .ofType(GetClinicalStudiesActionType.GET_CLINICAL_STUDIES_START)
      .flatMap(() => {
        const accessToken = selectAccessToken(store$.getState());
        return Observable.fromPromise(getClinicalStudiesService(accessToken))
          .switchMap((response) => {
            return [getClinicalStudiesSuccess(response)];
          })
          .pipe(
            catchError((response) =>
              Observable.of(getClinicalStudiesError(response)),
            ),
          );
      });
