import {
  ACTION_ICON_ADD,
  ACTION_ICON_ARROW_DOWN,
  ACTION_ICON_ARROW_LEFT,
  ACTION_ICON_ARROW_UP,
  ACTION_ICON_CHECKMARK_FILLED,
  ACTION_ICON_CHECKMARK,
  ACTION_ICON_CHEVRON_DOWN,
  ACTION_ICON_CHEVRON_UP,
  ACTION_ICON_CHEVRON_LEFT,
  ACTION_ICON_CHEVRON_RIGHT,
  ACTION_ICON_CLOSE_FILLED,
  ACTION_ICON_CLOSE,
  ACTION_ICON_CLOUD_UPLOAD,
  ACTION_ICON_EDIT,
  ACTION_ICON_FILTER,
  ACTION_ICON_HOME,
  ACTION_ICON_LAUNCH,
  ACTION_ICON_LIST_BULLET,
  ACTION_ICON_LOCKED,
  ACTION_ICON_MAXIMIZE,
  ACTION_ICON_MINIMIZE,
  ACTION_ICON_VIEW_NEXT,
  ACTION_ICON_NOTIFICATION,
  ACTION_ICON_PAGE_FIRST,
  ACTION_ICON_PAGE_LAST,
  ACTION_ICON_PERCENTAGE,
  ACTION_ICON_SEARCH,
  ACTION_ICON_SETTINGS,
  ACTION_ICON_SUBTRACT,
  ACTION_ICON_SWITCHER,
  ACTION_ICON_TAGS,
  ACTION_ICON_VIDEO,
  ACTION_ICON_VIDEO_OFF_FILLED,
  DEPRECATED_ACTION_ICON_CHAT,
  DEPRECATED_ACTION_ICON_NOTIFICATION_FILLED,
  DEPRECATED_ACTION_ICON_SEND_ALT_FILLED,
  DEPRECATED_ACTION_ICON_STAR,
} from '@rdcs/jello-icons/esm/action';

import {
  CALENDAR_ICON_CALENDAR,
  CALENDAR_ICON_EVENT,
  CALENDAR_ICON_TIME,
  CALENDAR_ICON_TIMER,
} from '@rdcs/jello-icons/esm/calendar';
import {
  COMMERCE_ICON_BUILDING,
  COMMERCE_ICON_INVENTORY_MANAGEMENT,
} from '@rdcs/jello-icons/esm/commerce';
import {
  DEPRECATED_DATA_ICON_VALUE_HIGHEST,
  DEPRECATED_DATA_ICON_VALUE_LOWEST,
  DEPRECATED_DATA_ICON_CHART_SCATTER,
  DATA_ICON_CHART_BOXPLOT,
  DATA_ICON_DOT_MARK,
  DATA_ICON_TREND,
  DATA_ICON_NO_DATA,
} from '@rdcs/jello-icons/esm/data';
import {
  DEVICE_ICON_ACCU_CHEK_GUIDE,
  DEVICE_ICON_ACCU_CHEK_INSIGHT_PUMP,
  DEVICE_ICON_ACTIVE_TEST_STRIPS,
  DEVICE_ICON_CGM_PATCH,
  DEVICE_ICON_GLUCOSE_DEVICES,
} from '@rdcs/jello-icons/esm/device';

import {
  DEPRECATED_DIABETES_ICON_FILL_CANNULA,
  DEPRECATED_DIABETES_ICON_FILL_TRANSFER_SET_TUBING,
  DIABETES_ICON_BASAL_TEMP,
  DIABETES_ICON_BEDTIME,
  DIABETES_ICON_CARTRIDGE_CHANGE,
  DIABETES_ICON_EXTENDED_BOLUS,
  DIABETES_ICON_LOGBOOK,
  DIABETES_ICON_MULTIWAVE_BOLUS,
  DIABETES_ICON_PRIME_INFUSION_SET,
  DIABETES_ICON_QUICK_BOLUS,
  DIABETES_ICON_QUICK_MANUAL,
  DIABETES_ICON_STANDARD_BOLUS,
} from '@rdcs/jello-icons/esm/diabetes';

import {
  FILE_ICON_DOCUMENT_EXPORT,
  FILE_ICON_DOCUMENT_SIGNATURE,
  FILE_ICON_NOTE,
  DEPRECATED_FILE_ICON_DOCUMENT_INFO,
} from '@rdcs/jello-icons/esm/file';

import {
  FOOD_ICON_AFTER_MEAL,
  FOOD_ICON_BEFORE_MEAL,
  FOOD_ICON_FASTING,
} from '@rdcs/jello-icons/esm/food';

import { HEALTH_ICON_HEALTH_CROSS } from '@rdcs/jello-icons/esm/health';

import {
  DEPRECATED_MEDIA_ICON_SETTINGS_ADJUST,
  MEDIA_ICON_PAUSE_OUTLINE,
  MEDIA_ICON_PLAY_OUTLINE,
  MEDIA_ICON_POWER,
  MEDIA_ICON_STOP_OUTLINE,
} from '@rdcs/jello-icons/esm/media';

import {
  STATUS_ICON_HELP,
  STATUS_ICON_INFORMATION,
  STATUS_ICON_WARNING_ALT_FILLED,
  STATUS_ICON_WARNING_ALT,
  STATUS_ICON_WARNING_FILLED,
  STATUS_ICON_WARNING,
  STATUS_ICON_SUCCESS_FILLED,
  STATUS_ICON_INFORMATION_FILLED,
} from '@rdcs/jello-icons/esm/status';
import {
  DEPRECATED_TECHNOLOGY_ICON_DEVICES,
  TECHNOLOGY_ICON_FORUM,
  TECHNOLOGY_ICON_LINKED,
  TECHNOLOGY_ICON_PRINTER,
  DEPRECATED_TECHNOLOGY_ICON_LAPTOP,
} from '@rdcs/jello-icons/esm/technology';

import { DEPRECATED_TRAVEL_ICON_BROOM } from '@rdcs/jello-icons/esm/travel';

import {
  DEPRECATED_USER_ICON_USER_ACTIVITY,
  DEPRECATED_USER_ICON_USER_AVATAR,
  DEPRECATED_USER_ICON_USER_DATA,
  USER_ICON_ADD_HCP_PROFILE,
  USER_ICON_ADD_PATIENT,
  USER_ICON_EDIT_PATIENT,
  USER_ICON_HCP,
  USER_ICON_USER_PROFILE,
  USER_ICON_USER,
  USER_ICON_SPORTS,
} from '@rdcs/jello-icons/esm/user';

import {
  DEPRECATED_WEATHER_ICON_SUNNY,
  WEATHER_ICON_NIGHT,
  WEATHER_ICON_AWAKE,
} from '@rdcs/jello-icons/esm/weather';

export const JELLO_ICONS_LIST = [
  ACTION_ICON_ADD,
  ACTION_ICON_ARROW_DOWN,
  ACTION_ICON_ARROW_LEFT,
  ACTION_ICON_ARROW_UP,
  ACTION_ICON_CHECKMARK_FILLED,
  ACTION_ICON_CHECKMARK,
  ACTION_ICON_CHEVRON_DOWN,
  ACTION_ICON_CHEVRON_LEFT,
  ACTION_ICON_CHEVRON_RIGHT,
  ACTION_ICON_CHEVRON_UP,
  ACTION_ICON_CLOSE_FILLED,
  ACTION_ICON_CLOSE,
  ACTION_ICON_CLOUD_UPLOAD,
  ACTION_ICON_EDIT,
  ACTION_ICON_FILTER,
  ACTION_ICON_HOME,
  ACTION_ICON_LAUNCH,
  ACTION_ICON_LIST_BULLET,
  ACTION_ICON_LOCKED,
  ACTION_ICON_MAXIMIZE,
  ACTION_ICON_MINIMIZE,
  ACTION_ICON_NOTIFICATION,
  ACTION_ICON_PAGE_FIRST,
  ACTION_ICON_PAGE_LAST,
  ACTION_ICON_PERCENTAGE,
  ACTION_ICON_SEARCH,
  ACTION_ICON_SETTINGS,
  ACTION_ICON_SUBTRACT,
  ACTION_ICON_SWITCHER,
  ACTION_ICON_TAGS,
  ACTION_ICON_VIDEO_OFF_FILLED,
  ACTION_ICON_VIDEO,
  ACTION_ICON_VIEW_NEXT,
  CALENDAR_ICON_CALENDAR,
  CALENDAR_ICON_EVENT,
  CALENDAR_ICON_TIME,
  CALENDAR_ICON_TIMER,
  COMMERCE_ICON_BUILDING,
  COMMERCE_ICON_INVENTORY_MANAGEMENT,
  DATA_ICON_CHART_BOXPLOT,
  DATA_ICON_DOT_MARK,
  DATA_ICON_NO_DATA,
  DATA_ICON_TREND,
  DEPRECATED_ACTION_ICON_CHAT,
  DEPRECATED_ACTION_ICON_NOTIFICATION_FILLED,
  DEPRECATED_ACTION_ICON_SEND_ALT_FILLED,
  DEPRECATED_ACTION_ICON_STAR,
  DEPRECATED_DATA_ICON_CHART_SCATTER,
  DEPRECATED_DATA_ICON_VALUE_HIGHEST,
  DEPRECATED_DATA_ICON_VALUE_LOWEST,
  DEPRECATED_DIABETES_ICON_FILL_CANNULA,
  DEPRECATED_DIABETES_ICON_FILL_TRANSFER_SET_TUBING,
  DEPRECATED_FILE_ICON_DOCUMENT_INFO,
  DEPRECATED_MEDIA_ICON_SETTINGS_ADJUST,
  DEPRECATED_TECHNOLOGY_ICON_DEVICES,
  DEPRECATED_TECHNOLOGY_ICON_LAPTOP,
  DEPRECATED_TRAVEL_ICON_BROOM,
  DEPRECATED_USER_ICON_USER_ACTIVITY,
  DEPRECATED_USER_ICON_USER_AVATAR,
  DEPRECATED_USER_ICON_USER_DATA,
  DEPRECATED_WEATHER_ICON_SUNNY,
  DEVICE_ICON_ACCU_CHEK_GUIDE,
  DEVICE_ICON_ACCU_CHEK_INSIGHT_PUMP,
  DEVICE_ICON_ACTIVE_TEST_STRIPS,
  DEVICE_ICON_CGM_PATCH,
  DEVICE_ICON_GLUCOSE_DEVICES,
  DIABETES_ICON_BASAL_TEMP,
  DIABETES_ICON_BEDTIME,
  DIABETES_ICON_CARTRIDGE_CHANGE,
  DIABETES_ICON_EXTENDED_BOLUS,
  DIABETES_ICON_LOGBOOK,
  DIABETES_ICON_MULTIWAVE_BOLUS,
  DIABETES_ICON_PRIME_INFUSION_SET,
  DIABETES_ICON_QUICK_BOLUS,
  DIABETES_ICON_QUICK_MANUAL,
  DIABETES_ICON_STANDARD_BOLUS,
  FILE_ICON_DOCUMENT_EXPORT,
  FILE_ICON_DOCUMENT_SIGNATURE,
  FILE_ICON_NOTE,
  FOOD_ICON_AFTER_MEAL,
  FOOD_ICON_BEFORE_MEAL,
  FOOD_ICON_FASTING,
  HEALTH_ICON_HEALTH_CROSS,
  MEDIA_ICON_PAUSE_OUTLINE,
  MEDIA_ICON_PLAY_OUTLINE,
  MEDIA_ICON_POWER,
  MEDIA_ICON_STOP_OUTLINE,
  STATUS_ICON_HELP,
  STATUS_ICON_INFORMATION,
  STATUS_ICON_WARNING_ALT_FILLED,
  STATUS_ICON_WARNING_ALT,
  STATUS_ICON_WARNING_FILLED,
  STATUS_ICON_WARNING,
  TECHNOLOGY_ICON_FORUM,
  TECHNOLOGY_ICON_LINKED,
  TECHNOLOGY_ICON_PRINTER,
  USER_ICON_ADD_HCP_PROFILE,
  USER_ICON_ADD_PATIENT,
  USER_ICON_EDIT_PATIENT,
  USER_ICON_HCP,
  USER_ICON_SPORTS,
  USER_ICON_USER_PROFILE,
  USER_ICON_USER,
  WEATHER_ICON_AWAKE,
  WEATHER_ICON_NIGHT,
  STATUS_ICON_INFORMATION_FILLED,
];
