import { testId } from '@roche/roche-common';
import React from 'react';
import {
  JELLO_TOOLBAR_ORIENTATIONS,
  JELLO_TOOLBAR_SIZES,
} from 'src/app/app.jello.constants';
import { ADMINISTRATIVE } from 'src/app/store/user/user.constants.js';
import { RenderIf } from 'src/shared/utils/render-if';
import { PatientActionsComponent } from '../patient-actions-jello/patient-actions-jello.component';
import styles from './styles.module.css';
import { PatientMainData } from './components/patient-main-data';
import { PatientSecondaryData } from './components/patient-secondary-data';

const ToolBarDivider = () => <div className={styles.toolbarDivider} />;

export const isMatchingPatientIdInUrl = (
  patientId: string,
  pathname: string,
) => {
  return pathname.includes(patientId);
};

type PatientSecondaryDataProps = {
  patient: {
    dateOfBirth: string | null;
    diabetesTypeEC6: string | null;
    diagnosticDate: string | null;
    healthCareSystemId: string | null;
    therapyType: string | null;
    id: string;
  };
  isPatientIdMatchingUrl: boolean;
  isPlatformUser: boolean;
};

const defaultPatientProps = {
  dateOfBirth: null,
  diabetesTypeEC6: null,
  diagnosticDate: null,
  healthCareSystemId: null,
  therapyType: null,
  isPlatformUser: true,
};

export const getPatientSecondaryDataProps = ({
  patient,
  isPatientIdMatchingUrl,
  isPlatformUser,
}: PatientSecondaryDataProps) => {
  return isPatientIdMatchingUrl
    ? { ...patient, isPlatformUser }
    : { ...defaultPatientProps, isPlatformUser };
};

export const PatientDataBarComponent = ({
  patient,
  profile,
  isPlatformUser,
  visitModuleActiveSection,
  setVisitModuleActiveSection,
  location,
}) => {
  const isPatientIdMatching = isMatchingPatientIdInUrl(
    patient.id,
    location.pathname,
  );

  const jelloToolbarProps = {
    orientation: JELLO_TOOLBAR_ORIENTATIONS.HORIZONTAL,
    size: JELLO_TOOLBAR_SIZES.S,
    style: {
      borderBottom: '1px solid var(--jello-color-background-alternative)',
      marginBottom: 0,
    },
  };

  const patientMainDataProps = {
    surName: patient.surName,
    firstName: patient.firstName,
  };

  const patientSecondaryDataProps = getPatientSecondaryDataProps({
    patient,
    isPatientIdMatchingUrl: isPatientIdMatching,
    isPlatformUser,
  });

  const patientActionsComponentProps = {
    visitModuleActiveSection,
    setVisitModuleActiveSection,
  };

  return (
    <jello-toolbar
      {...jelloToolbarProps}
      {...testId('jello-toolbar', 'patient-databar')}
    >
      <div className={styles.toolbarPatientInfoWrapper}>
        <RenderIf validate={isPatientIdMatching}>
          <PatientMainData {...patientMainDataProps} />
        </RenderIf>
        <ToolBarDivider />
        <PatientSecondaryData {...patientSecondaryDataProps} />
      </div>
      <jello-toolbar-spacer />
      <RenderIf validate={profile !== ADMINISTRATIVE && isPatientIdMatching}>
        <PatientActionsComponent {...patientActionsComponentProps} />
      </RenderIf>
    </jello-toolbar>
  );
};
