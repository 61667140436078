import { getConfig } from '@roche/roche-common';
import {
  CONTENTS,
  ID,
  INFOS_COUNTLY,
  INFOS_COUNTLY_SECTIONS,
  PREFIX,
  TITLE,
} from './additional-info.constants';
import { TFunction } from 'i18next';
import { pathOr } from 'ramda';
import { Section } from './additional-info.content';
import { countlyEventTrigger } from 'src/app/navigation/countly';
import { contentType } from './additional-info.container';

export const getProps = (
  t: TFunction,
  info: string,
  profile: string,
  active?: string,
  condition?: { [key: string]: () => boolean },
) => {
  return {
    id: ID,
    headerTitle: t(`${PREFIX}${info}.${TITLE}`),
    showCloseButton: true,
    closeOnAction: null,
    primaryButtonText: null,
    secondaryButtonText: null,
    tertiaryButtonText: null,
    info,
    active,
    condition,
    ...pathOr(pathOr(null, [info], CONTENTS), [info, profile], CONTENTS),
  };
};

export const additionalInfoActivated = () =>
  `${getConfig().REACT_APP_ADDITIONAL_INFO}` === 'true';

export const filterSections = (
  sections: Section[],
  condition?: { [key: string]: () => boolean },
) => {
  return sections.filter((section) =>
    section.conditionalSection && condition && condition[section.info]
      ? condition[section.info]()
      : true,
  );
};

const getComposedSegmentValue = ({ segmentKey, infoValue, activeValue }) => {
  const suffix = INFOS_COUNTLY_SECTIONS[infoValue][activeValue];

  const segmentValue = suffix ? `${segmentKey}-${suffix}` : segmentKey;

  return segmentValue;
};

export const openAdditionalInfoCountlyEvent = (content: contentType) => {
  const infoValue = content.info;
  const activeValue = content.active;

  const segmentKey = INFOS_COUNTLY[infoValue];
  const segmentValue = activeValue
    ? getComposedSegmentValue({ segmentKey, infoValue, activeValue })
    : segmentKey;

  countlyEventTrigger('Additional Information opened', {
    ['section']: segmentValue,
  });
};
