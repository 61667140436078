import {
  createAuthHeader,
  getJSON,
} from '../../../../../../../shared/utils/service/service.utils';
import { ENDPOINTS } from '../../../../../../../app/navigation/services/service.constants';
import {
  GetClinicalStudiesLoaderImplType,
  GetClinicalStudiesResponseType,
  GetClinicalStudiesServiceImplType,
  GetClinicalStudiesTransformImpType,
} from './get-clinical-studies.types';
import { transformClinicalStudiesResponse } from '../../utils';

export const GetClinicalStudiesLoaderImpl: GetClinicalStudiesLoaderImplType = (
  accessToken: string,
) => {
  return getJSON(ENDPOINTS.getClinicalStudies, {
    headers: {
      Authorization: createAuthHeader(accessToken),
    },
  });
};

export const GetClinicalStudiesTransformImpl: GetClinicalStudiesTransformImpType =
  (response: GetClinicalStudiesResponseType) =>
    transformClinicalStudiesResponse(response);

export const GetClinicalStudiesServiceImpl: GetClinicalStudiesServiceImplType =
  (load, transform) => (accessToken: string) =>
    load(accessToken).then(transform);
